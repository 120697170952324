@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");

/* * {
  background-color: #f6f6f6;
} */

body {
  font-family: "Poppins", sans-serif;
  background-color: #f6f6f6;
}
